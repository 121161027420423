<template>
  <scars-template
    :acne-scars="acneScars"
    :acne-scars-ai-selected="acneScarsAiSelected"
    :face-scars-appearance="faceScarsAppearance"
    :face-scars-appearance-visible="faceScarsAppearanceVisible"
    :field-errors="fieldErrors"
    @field-change="onFieldChange"
  />
</template>

<script>
import ScarsTemplate from '@/modules/questionnaire/components/steps/photo-analysis/skin-appearance/scars/ScarsTemplate';

import { no, yes } from '@/modules/questionnaire/api/helpers';
import { fieldBuilder } from '@/modules/questionnaire/mixins/makeStep';
import { makeStep, mapAiSelectedFields, resetStoreValues } from '@/modules/questionnaire/mixins';

const { requiredField } = fieldBuilder;

const AI_SELECTED_FIELD_NAMES = ['acneScars'];

const FIELDS = [
  ...AI_SELECTED_FIELD_NAMES.map(requiredField),
  requiredField('faceScarsAppearance')
];

export default {
  name: 'Scars',
  components: { ScarsTemplate },
  mixins: [makeStep(FIELDS), mapAiSelectedFields(AI_SELECTED_FIELD_NAMES), resetStoreValues],
  computed: {
    faceScarsAppearanceVisible() {
      return yes(this.acneScars);
    }
  },
  watch: {
    acneScars(newValue, oldValue) {
      if (no(newValue)) {
        this.resetStoreFieldsByName(['faceScarsAppearance']);
      }

      if (!this.faceScarsAppearanceVisible) {
        this.showNextStep();
        return;
      }

      if (!oldValue && this.faceScarsAppearance) {
        this.showNextStep();
        return;
      }

      this.scrollTo('#face-scars-appearance');
    },
    faceScarsAppearance(newValue, oldValue) {
      if (!oldValue && this.acneScars) {
        this.showNextStep();
      }
    }
  },
  methods: {
    isFieldVisible(fieldName) {
      if (fieldName === 'faceScarsAppearance') {
        return this.faceScarsAppearanceVisible;
      }

      return true;
    }
  }
};
</script>
